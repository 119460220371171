<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">営業広告媒体の設定</h1>
        <banner-hint>
          こちらで登録した広告媒体名は予約情報作成の際に選択できるようになります。<br />
          電話予約や来店予約などでお客様が参考にした広告媒体を登録しておくことで月間統計に各媒体からの実客数が表示され広告効果の可視化に役立ちます。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        v-if="ads.length"
      >
        <v-card
          class="px-10 py-7"
          elevation="1"
        >
          <v-row no-gutters>
            <v-col cols="12" md="6"
              class="mb-5 pt-1"
              v-for="(ad, index) in ads"
              :key="ad.sales_ad_id"
            >

              <!-- 入力一列 -->
              <v-form :ref="'form-ad-' + ad.sales_ad_id">
                <v-row no-gutters>
                  <v-col cols="9" md="8"
                    class="px-1"
                  >
                    <v-text-field
                      v-model.trim="ad.ad_name"
                      required
                      label="広告媒体名"
                      counter="10"
                      :rules="[valiRules.required, valiRules.max10]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" md="4"
                    class="d-flex justify-start align-center pt-0 pl-2"
                    :class="$vuetify.breakpoint.mdAndUp ? 'justify-start' : 'justify-end'"
                  >
                    <v-btn
                      v-if="ad.create"
                      depressed small
                      color="accent"
                      @click="createRow(ad)"
                    >登録</v-btn>
                    <v-btn
                      v-else
                      depressed small
                      color="primary"
                      @click="updateRow(ad)"
                    >更新</v-btn>
                    <v-btn
                      class="ml-2"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow(index)"
                    >削除</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- 要素追加ボタン -->
      <v-col cols="12"
        class="mb-5"
      >
        <v-btn
        depressed
        color="primary"
        @click="addBlankRow()"
        >広告媒体を追加</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card v-if="!ads.length" flat>
          <v-card-text>広告媒体が登録されていません。</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- オーバーレイメッセージ -->
    <overlay-message ref="overlayMessage">
      <div v-html="modalMessage"></div>
    </overlay-message>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import OverlayMessage from '@/components/_OverlayMessage.vue'
import BannerHint from "@/components/_BannerHint.vue";

export default {
  components: {
    'loader': Loader,
    'overlay-message': OverlayMessage,
    'banner-hint': BannerHint,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxId: 0,
      ads: [],
      modalMessage: '',
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    };
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  mounted() {
    if (this.shopData.system_plan_id < 2) {
      this.modalMessage = $literals.MESSAGE.availableForPaidPlan
      this.$refs.overlayMessage.open()
    } else {
      this.adminApi.setToken(this.serverToken)

      this.getData()
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    }
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.setAds(),
      ])

      this.loading = false
    },

    //入力列追加
    addBlankRow() {
      if (this.ads.length >= 16) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.ads.push({
        sales_ad_id: ++this.maxId,
        ad_name: '',
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    //**************************************************
    setAds() {
      return this.adminApi.getReqWithAuth('sales-ad/').then( results => {
        if (!results || !results.length) return

        this.maxId = Math.max(...results.map( row => parseInt(row.sales_ad_id) ))
        this.ads = results
      })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(ad) {
      if (!this.$refs['form-ad-' + ad.sales_ad_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const apiPath = 'create/sales-ad/'
      const updateData = {ad_name: ad.ad_name}
      const payload = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('POST', apiPath, payload).then( response => {
        ad.sales_ad_id = response.sales_ad_id
        ad.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(ad) {
      if (!this.$refs['form-ad-' + ad.sales_ad_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const apiPath = 'update/sales-ad/' + ad.sales_ad_id
      const updateData = {ad_name: ad.ad_name}
      const payload = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('PUT', apiPath, payload).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.ads[index].create) {
        this.ads.splice(index, 1)
        return
      }

      const apiPath = 'delete/sales-ad/' + this.ads[index].sales_ad_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.ads.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },
  }
}
</script>

<style scoped>
>>> .v-input__slot {
  margin-bottom: 4px;
}
</style>
